var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',[_c('div',{staticClass:"o-rodo-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"headers":_vm.state.headers,"options":_vm.state.options,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"item.default",fn:function(ref){
var item = ref.item;
return [(item.default)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{ name: 'panel.rodo.edit', params: { id: item.id } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(item.default === false)?_c('v-dialog',{attrs:{"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1):_vm._e()],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }