
































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  props: {
    search: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(_, { root }) {
    const state = reactive({
      headers: [
        { value: "name", text: root.$tc("panel.event.add.alert.templateName") },
        {
          text: root.$tc("layout.misc.default"),
          value: "default",
          align: "right",
          width: 1,
        },
        { text: "", value: "actions", align: "right" },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("consent", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            type: "personal data protection",
          },
        })
        .then(({ data: { consents } }) => {
          state.empty = false;
          state.items = consents.map((consent: any, index: number) => ({
            id: index,
            name: "",
            ...consent,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`consent/${item.id}`)
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };
    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    return { state, fetchData, deleteItem };
  },
});
